import React from "react";
import "./App.css";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";

const App = () => {
  localStorage.setItem("path", "home");
  return (
    <>

      <div>
        <div className="app-container">
          <div className="app-background">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
          </div>
          <Home />
        </div>
        <Footer />
        <ScrollUp />
      </div>


    </>
  );
};

export default App;
