import React from "react";
import "./footer.css";
import noveltyLogo from '../../assets/noveltylogoTransparent.png'


const Footer = () => {
  return (
    <div className="footer__container">
      <div className="footer__box__widget">
        <div className="footer__logo">
        </div>
        <img className="noveltylogo" src={noveltyLogo} alt="NoveltySwap" />
        <div className="copyright__title">
          ©2023 NoveltySwap <br /> All Rights Reserved.
        </div>
      </div>

    </div >



  );
};

export default Footer;
