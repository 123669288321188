import React from "react";
import "./home.css";



const Data = () => {

  return (

    <div className="home__container">
      <div className="home__main__elements">
        <div className="home__text__box ">
          <div className="home__title">Novelty Swap</div>
          <div className="home__slogan">The unusual way of swapping crypto</div>
          <div className="construction__box">
            <div className="construction__text rbutton">Coming This May!<span></span></div>
          </div>
        </div>
      </div>

    </div >
  );
};

export default Data;
